<template>
  <Spinner>
    <div
      class="titleRow inline-block"
      :style="{ cursor: haveGenres ? 'pointer' : 'auto' }"
      @click="openCollection(collection.id)"
    >
      {{ collection.title }}
      <Icon
        v-if="haveGenres"
        class="pointer inline-block"
        icon="ui/arrow_right"
      />
    </div>

    <PosterSwiper
      :posters="collection.videos"
      :local-storage-key="collection.id"
      :collection-id="collection.id"
    />
  </Spinner>
</template>

<script setup>
import Spinner from '@/components/ui/Spinner.vue';
import PosterSwiper from '@/components/ui/Swipers/PosterSwiper.vue';
import Icon from '@/components/common/icons/icon.vue';

const props = defineProps({
  collection: {
    type: Object,
    required: true,
  },
  haveGenres: Boolean,
});

function openCollection(id) {
  if (props.haveGenres) navigateTo({ name: 'cinema-collection-name', params: { name: id } });
}
</script>
